<template>

  <div id="wrapper">
    <!-- Removed mb-2 to avoid having white space -->
    <div class="has-navbar-fixed-top">
        <nav class="navbar is-primary is-fixed-top">
          <div class="navbar-brand navbarpadding">
      
            
              <router-link to="/" class="navbar-item">Accueil</router-link>
            
      
            <a class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbar-menu" @click="showMobileMenu = !showMobileMenu">
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>
          <div class="navbar-menu" id="navbar-menu" v-bind:class="{'is-active':showMobileMenu}">
            <div class="navbar-start">  
      
            </div>
            <div class="navbar-end navbarpadding ">
              
              
              <div class="navbar-item">
                <div class="buttons">
                  <template v-if="$store.state.isAuthenticated">
                    <router-link to="/tableau-de-bord" class="button is-light"> Tableau de bord </router-link>
                  </template> 
                  <template v-else>
                  <router-link to="/log-in" class="button is-light" >Log in</router-link> 
                  </template>  
      
                </div>
              </div>
      
            </div>
          </div>
        </nav>
      
    </div>

      <div class="is-loading-bar has-text-centered" v-bind:class="{'is-loading ': $store.state.isLoading }">
        <div class="lds-dual-ring is-vcentered is-fullheight "></div>
      </div>


<div class="contenu">
    <router-view/>
</div>

     <footer class="footer has-background-primary-light">
      <div class="content has-text-primary">
        <div class="columns">
          <div class="column p-3">
                <div class="image is-128x128 p-3">
                  <img  src="@/assets/images/logo_elona_yellow.svg" style="max-width:5em">
                </div>
          </div>
          <div class="column">
            <h2 class="subtitle is-4 has-text-primary">A propos de nous</h2>

            <!-- <p> Notre mission </p> -->
            <p> Notre histoire </p>
            <p> Notre équipe </p>

          </div>
          <div class="column">
            <h2 class="subtitle is-4 has-text-primary">Legal</h2>

            <p> GDPR </p>
            <p> Mentions légales </p>
            <p>  </p>
          </div>
          <div class="column">
            <h2 class="subtitle is-4 has-text-primay">Nous contacter</h2>
            <!-- <div class="control has-icons-left has-icons-right p-5">
              <input class="input is-medium" type="email" placeholder="Email">
              <span class="icon is-left">
              <i class="fa fa-envelope"></i>
              </span>
            </div>
            <a class="button is-success is-rounded">Subscribe</a> -->
            <p> 00 41 76 368 18 38 </p>
            <p> Mentions légales </p>
          </div>
        </div>
      </div>
    </footer>

  <!-- <footer class="footer">
    <div class="columns is-centered">
      <div class="column is-11">

        <div class="tile is-ancestor">
          <div class="columns is-centered is-multiline">

            <div class="tile is-parent is-vertical is-5">
              <div class="tile is-child">

                <div class="container">
                  <p class="text has-text-white">
                    E-cco est une startup genevoise créée en 2022. Elle participe à la transition énergétique en faisant bénéficier ses acteurs des avancées numériques.
                  </p>
                </div>
              </div>
            </div>

            <div class="tile is-parent is-3">
              <div class="tile is-child">
                <div class="content has-text-white">
                  <p class="title has-text-white is-5">  <br><br>
                  </p>
                </div>
              </div>
            </div>
            <div class="tile is-parent is-1">
              <div class="tile is-child">
                <div class="content has-text-white">
                  <p class="title has-text-white is-5">  <br><br>
                  </p>
                </div>
              </div>
            </div>


            <div class="tile is-parent is-3">
              <div class="tile is-child">
                <div class="content has-text-white">
                  <p class="title has-text-white is-5"> Nous contacter: </p> <p><br><br>
                    Téléphone: <br> 00 41 76 368 18 38 <br><br>
                    Email: <br>marketing.team@energie-expert.ch <br><br>
                  </p>
                </div>
              </div>
            </div>

          </div>

        </div>


    </div>
   </div> 
  </footer> -->

  </div>

  
</template>



<script>
import axios from 'axios'

export default {
   data(){
     return{
       showMobileMenu:false,
       cart: {
         items: []
       }
     }
   },

   beforeCreate(){
     this.$store.commit('initializeStore')
     this.$store.commit('initializeAdresse')

     const token = this.$store.state.token

     if (token){
       axios.defaults.headers.common['Authorization'] = "Token " + token
     }else {
       axios.defaults.headers.common['Authorization'] = ""
     }
   },

 
}
</script>


<style lang="scss">
$primary: #1d492b;
$link: #f89209;
$footer-background-color : #1d492b;
$navbar-padding-horizontal:size-2;
$navbar-item-color:#f89209;

@import '/node_modules/bulma';

.contenu {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

#app {
  flex: 1;
  display: flex;
  flex-direction: column;
}


.lds-dual-ring {

  display: inline-block;
  width: 80px;
  height: 80px;
  
}
.lds-dual-ring:after {

  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #ccc;
  border-color: #ccc transparent #ccc transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.is-loading-bar {
  height: 0;
  overflow:hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  &.is-loading {
    height: 100px;
    margin:7rem;
  }
}

@media only screen and (min-width: 768px) {
  .navbarpadding{
  padding-right: 3rem;
  padding-left: 3rem;
}

}


// IMPORTANT ADDINGS TO AVOID HAVING THOSE WEIRD WHITE SPACES
// html,body{
//     overflow-x: hidden;
// }

.navbar-default { margin : 0 !important; }
</style>

