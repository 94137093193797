<template>
<div class="ChoixSim">
    <div class="container p-6">
        <div class="column is-10 pt-6">
          <div class="title">Quel estimation souhaitez-vous?</div>
          <div class="tile is-ancestor">
                <div class="tile is-vertical is-parent is-4">
                    <div class="container">
                      <div class="card">
                        <div class="notification is-warning is-light my-5"><div class="subtitle"><b>Simulation eclair</b></div></div>
                        <div class="container pb-5 mx-5 ">
                          <div class="text">
                            <p>
                            <b>Temps estimé:</b>
                            <br>
                            1 minute
                            <br><br>
                            Entrez votre adresse et notre algorithme vous retourne une première fourchette ! 
                            <br><br>
                            <b>Degré de précision des résultats:</b>
                            <br>
                            L'open data ne contient pas certaines données importantes. Si votre maison a plus de 30 ans où a certaines particularités, nous vous recommendons d'utiliser l'estimation complète.

                          </p>
                          </div>
                        </div>
                      </div>


                    </div>
                  
                </div>
                <div class="tile is-vertical is-parent is-4">
                    <div class="container">
                      <div class="card">
                        <div class="notification is-warning is-light my-5"><div class="subtitle">Simulation avancée</div></div>
                        <div class="container pb-5 mx-5">
                          <div class="text">
                            <p>
                            <b>Temps estimé:</b>
                            <br>
                            5 minutes
                            <br><br>
                            Vous entrez des données via un questionnaire afin que nous puissions affiner les résultats de l'algorithme.
                            <br><br>
                            <b>Degré de précision des résultats:</b>
                            <br>
                            L'estimation devient beaucoup plus précise.




                          </p>
                          </div>
                        </div>
                      </div>


                    </div>
                  
                </div>
                <div class="tile is-vertical is-parent is-4">
                    <div class="container">
                      <div class="card">
                        <div class="notification is-warning is-light my-5"><div class="subtitle">Expert agréé</div></div>
                        <div class="container pb-5 mx-5  ">
                          <div class="text">
                            <p>
                            <b>Temps estimé:</b>
                            <br>
                            Rappel dans les 24h.
                            <br><br>
                            Obtenez directement votre classe officielle en faisant appel à un expert agréé. Nous trouvons un expert près de chez vous et lui envoyons un formulaire avec vos détails et vos coordonnées afin qu'il puisse vous contacter et vous proposer son offre.

                            <br><br>
                            <b>Degré de précision des résultats:</b>
                            <br>
                            L'estimation devient officielle et fait foi devant la loi.




                          </p>
                          </div>
                        </div>
                      </div>


                    </div>
                  
                </div>   
          </div>
          <div class="is-align-content-flex-end">
            <div class="container pt-6">
              <button class="button is-dark  is-pulled-right">Poursuivre vers la simulation</button>
            </div>
          </div>
        </div>
    </div>
</div>

</template>