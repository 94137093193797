<template>

<div id="formulaire-devis">
        <div class="hero is-fullheight has-background-primary-light pt-6">
            <div class="container p-4 pt-6">
        <div class="tile is-ancestor">
            <div class="tile is-6">
                <div class="container p-6">

                    <div class="tile is-child">
                        <div class="title is-primary">Le service E-cco</div>
                        <div class="subtitle">Accélerer la mise en contact</div>
                        <div class="text">
                            Sur la base de nos algorithmes et du feedback de tous les utilisateurs de cette plateforme, nous avons trouvé 3 entreprises qui seront à même de vous fournir l'information nécecessaire sur leurs prix, leurs argument compétitifs, leurs expertise.
                    

                        </div>
                        <div class="container p-6">
                            <div class="container p-6">
                                <!-- <div class="container p-6"> -->
                                    <figure class="image">
                                        <img class=" is-128x128 is-inline-block" src="@/assets/images/undraw_people_search_re_5rre.svg">
                                    </figure>
                                <!-- </div> -->
                            </div>
                        </div>
                    </div>

                </div>

              
            </div>
            <div class="tile">
                <div class="container p-5">
                    <div class="tile is-child box">
                        <div class="title">Recevez vos devis gratuitement</div>
                        <div class="container">
                        <div class="subtitle has-text-grey">Obtenez les informations en direct du marché.
                            <br>
                            
                        </div>
                        <p> <em> Cette demande de devis concerne le bâtiment à l'adresse: </em><strong> {{ adresse }} </strong> </p>
                        <br>
                        <br>
                        <div class="field">
    
                            <div class="field-body">
                              <div class="field">
                                <label class="label">Nom <span class="has-text-danger has-text-weight-normal">*</span></label>

                                <p class="control is-expanded">
                                  <input class="input" type="name" placeholder="Favre" v-model="nom" name="nom">
                                  <span class="icon is-small is-left">
                                    <i class="fas fa-user"></i>
                                  </span>
                                </p>
                              </div>
                              <div class="field">
                                <label class="label"> Prénom <span class="has-text-danger has-text-weight-normal">*</span></label>

                                <p class="control is-expanded has-icons-left has-icons-right">
                                  <input class="input" type="name" placeholder="Elona" v-model="prenom" name="prenom">
                                  <span class="icon is-small is-left">
                                    <i class="fas fa-envelope"></i>
                                  </span>
                                  <span class="icon is-small is-right">
                                    <i class="fas fa-check"></i>
                                  </span>
                                </p>
                              </div>
                            </div>

                            <div class="field-body">
                                <div class="field">
                                  <label class="label">Email <span class="has-text-danger has-text-weight-normal">*</span></label>
  
                                  <p class="control is-expanded">
                                    <input class="input" type="email" placeholder="elona@elona-construction.ch" v-model="email" name="email">
                                    <span class="icon is-small is-left">
                                      <i class="fas fa-user"></i>
                                    </span>
                                  </p>
                                </div>
                                <div class="field">
                                  <label class="label">Numéro de téléphone <span class="has-text-danger has-text-weight-normal">*</span></label>
  
                                  <p class="control is-expanded has-icons-left has-icons-right">
                                    <input class="input" type="tel" placeholder="+41 76 368 18 38" v-model="telephone" name="téléphone">
                                    <span class="icon is-small is-left">
                                      <i class="fas fa-envelope"></i>
                                    </span>
                                    <span class="icon is-small is-right">
                                      <i class="fas fa-check"></i>
                                    </span>
                                  </p>
                                </div>
                            </div>



                              <div class="field-body">
                                <div class="field">
                                  <label class="label">Adresse de contact <span class="has-text-danger has-text-weight-normal">*</span></label>
  
                                  <div class="control is-expanded">
                                    <input class="input" type="name" placeholder="Rue du Lac 6 1200 Genève" v-model="adresseContact" :disabled="identique == 1" name="Adress">

                                  <div class="control pt-2 pb-4">
                                    <label class="checkbox">
                                        <input type="checkbox" v-model="identique" name="AdresseIdentique">
                                         Adresse de contact identique à l'adresse du bâtiment
                                    </label>
                                  </div>
                                  </div>                               
                                </div>
  
                              </div>
                                                    
                            <div class="field-body">
                                <div class="field">
                                    <label class="label">Décrivez vos besoins particuliers.</label>

                                    <div class="control">
                                        <textarea class="textarea" placeholder="Besoins particuliers..." v-model="besoins" maxlength="900"></textarea>
                                      </div>
                                </div>
                              
                            </div>
                          <div class="notification is-danger mt-4" v-if="errors.length">
                              <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
                          </div>

                        </div>
                            <div class="tile is-horizontal has-background-link-light">

                                <div class="column is-6">
                                    <div class="field is-narrow">
                                        <div class="control">
                                            <label class="checkbox">
                                            <input type="checkbox"  v-model="facade" name="façade">
                                            Rénovation de la facade
                                            </label>
                                        </div>
                                        </div>
                                        <div class="field is-narrow">
                                            <div class="control">
                                            <label class="checkbox">
                                                <input type="checkbox" v-model="toiture" name="toiture" >
                                                Rénovation de la toiture
                                            </label>
                                            </div>
                                        </div>
                          
                                    <div class="field is-narrow">
                                        <div class="control">
                                        <label class="checkbox">
                                            <input type="checkbox" v-model="fenetre" name="fenêtre">
                                            Remplacement des fenêtres
                                        </label>
                                        </div>
                                    </div>
                                </div>
                            
                                <div class="column is-6">
                                    <div class="field is-narrow">
                                        <div class="control">
                                        <label class="checkbox">
                                            <input type="checkbox" v-model="solaire" name="solaire">
                                            Installation panneaux solaires
                                        </label>
                                        </div>
                                    </div>
                      
                                    <div class="field is-narrow">
                                        <div class="control">
                                        <label class="checkbox">
                                            <input type="checkbox" v-model="chauffage" name="chauffage" >
                                            Remplacement du chauffage
                                        </label>
                                        </div>
                                    </div>
                                    <div class="field is-narrow">
                                        <div class="control">
                                        <label class="checkbox">
                                            <input type="checkbox" v-model="expertise" name="expertise">
                                            Expertise énergétique
                                        </label>
                                        </div>
                                    </div>
                                </div>
                            <!-- </div> -->
                        </div>
                        <div class="container p-4 is-horizontal has-background-link-light">
                            <label class="label">Je souhaite recevoir...</label>
                            <div class="field">
                                <div class="select is-fullwidth has-background-primary-light">
                                    <select v-model="selection">
                                    <option>Recevoir 3 appels d'offres par intervention via email</option>
                                    <option>Recevoir un seul appel d'offre par téléphone</option>
                    
                                    </select>
                                </div>
                            </div>
                            
                        </div>
                          <div class="field is-horizontal">
                            <div class="field-label">
                              <!-- Left empty for spacing -->
                            </div>
                            <div class="field-body">
                              <div class="field p-4">
                                <div class="control">
                                  <button class="button is-primary" @click="submitForm">
                                    Lancer mes demandes de devis
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>


                </div>
            </div>
          </div>

        </div>
        </div>
        </div>
        </div>
</div>

</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'

export default {
    name: 'FormulaireDevis',
    components: {

    },
    data() {
        return {
          nom:'',
          prenom:'',
          email:'',
          telephone:'',
          adresseContact:'',
          identique:false,


          besoins:'',
          facade:false,
          toiture:false,
          fenetre:false,
          solaire:false,
          chauffage:false,
          expertise:false,
          selection:"Recevoir 3 appels d'offres par intervention via email",
          
          errors:[],

          orders: []
        
        }
    },
    mounted() {
        document.title = 'Devis | Elona Construction'
        this.setSelectionDevis()

    },
    methods: {
      submitForm(){
        this.errors = []
            if (this.nom === '') {
                this.errors.push('Veuillez remplir le champ "Nom"')
            }
            if (this.prenom === '') {
                this.errors.push('Veuillez remplir le champ "Prénom"')
            }
            if (this.email === '') {
                this.errors.push('Veuillez remplir le champ "Email"')
            }
            if (this.telephone === '') {
                this.errors.push('Veuillez remplir le champ "Numéro de téléphone"')
            }
            if (this.adresseContact === '') {
                this.errors.push('Veuillez remplir le champ "Adresse de contact"')
            }
            if (this.besoins.length > 900) {
                this.errors.push('Le champ "besoins" est trop long')
            }

            if (!this.errors.length){
              this.sendData()
            }

      },
      async sendData(){
        this.$store.commit('setIsLoading', true)

        const data = {
          'nom':this.nom,
          'prenom':this.prenom,
          'email':this.email,
          'telephone':this.telephone,
          'adresseBatiment': this.$store.getters.getAdresse,
          'adresseContact':this.adresseContact,
          'identique':this.identique,

          'besoins':this.besoins,
          'facade': this.facade,
          'toiture':this.toiture,
          'fenetre':this.fenetre,
          'solaire':this.solaire,
          'chauffage':this.chauffage,
          'expertise':this.expertise,
          'selection':this.selection,
            }
        console.log('this is the data to be send: ',data)

        await axios
        .post('/api/v1/devis/', data)
        .then(response => {

        console.log("RESPONSE SERVER: ",response.data)
        this.$router.push('/demande-ok')

      })
      .catch(error => {
          this.errors.push('Something went wrong. Please try again')
          console.log(error)
      })
      this.$store.commit('setIsLoading', false)
      },

      setSelectionDevis(){
        let checked = this.$store.getters.getChecked
        console.log("voici checked : ", checked)

          this.facade = checked.checkedFaçade
          this.toiture = checked.checkedToiture
          this.fenetre = checked.checkedComble

          this.solaire = checked.checkedSolaire

          this.chauffage = checked.checkedChauffage
      }

        

    },
  computed:{
    ...mapState(['adresse']),
  },
  watch:{
    identique: function(){
      if (this.identique === true){
        this.adresseContact = this.$store.getters.getAdresse
      }
      if (this.identique === false){
        this.adresseContact = ''
      }
    },
  },

}
</script>

