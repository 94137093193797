<template>

<div id="simulations">

<div class="tile is-ancestor mt-6">

</div>
  <div class="tile is-ancestor mt-6 ">

    <div class="tile is-vertical is-8">
      <div class="tile">
        <div class="tile is-parent is-vertical">
          
          <article class="tile is-child notification is-white">
            
            <div class="card">
              <header class="card-header">
                <p class="card-header-title subtitle is-spaced has-text-weight-bold has-text-primary">
                  Situation actuelle
                </p>
              </header>
              <div class="card-content">
                <div class="columns is-vcentered">
                  <div class="column">
                    <p>Classe</p>
                    <h1 class="is-size-3  has-text-weight-bold">{{ class_before }}</h1>
                  </div>
                </div>
              </div>
            <div class="card">
              <div class="card-content">
                <div class="columns">
                    <div class="column">
                    <p>Réduction des emissions de CO2</p>
                    <h1 class="is-size-4  has-text-weight-bold">{{ reduction_co2 }} T/an </h1>
                  </div>
               <div class="column">
                    <p>Subventions disponibles aujourd'hui</p>
                    <h1 class="is-size-4  has-text-weight-bold">{{ max_subv }} CHF </h1>
                  </div>
              </div>
              </div>
            </div>
            </div>

              <div class="card">
                <!-- <header class="card-header">
                  <p class="card-header-title subtitle is-spaced has-text-weight-bold has-text-primary">
                    Potentiel d'amélioration
                  </p>
                </header> -->
                <div class="card-content">
                  <div class="columns">
                    <div class="column">
                      <p>Réduction de la consommation</p>
                      <h1 class="is-size-4  has-text-weight-bold">{{ consumption_reduction }} kWh/an</h1>
                    </div>
                      <div class="column">
                      <p>Economies annuelles</p>
                      <h1 class="is-size-4 has-text-weight-bold">{{ saving_energy_cost }} CHF/an</h1>

                      <!-- <h1 class="is-size-3  has-text-weight-bold">{{ saving_energy_cost }}</h1>
                    </div>
                      <div class="column">
                      <p>Durée pour profitabilité</p>
                      <h1 class="is-size-3  has-text-weight-bold">{{ duree_proftabilité }}</h1>
                    </div>         
      -->
                      </div>
    

                </div>
                </div>
            </div>

          </article>
          <article class="tile is-child notification is-white">
            <div class="card">
              <header class="card-header">
                <p class="card-header-title subtitle is-spaced has-text-weight-bold has-text-primary">
                  Potentiel de rénovation
                </p>
              </header>
              <div class="card-content">
              <article class="tile is-child notification is-white">
                <div class="tile is-ancestor">
                  <div class="tile is-vertical is-8">
                    <div class="tile">
                      <div class="tile is-parent is-vertical">
                        <article class="tile is-child notification is-white">
                          <img src="@/assets/images/image_maison.png" alt="image de maison">
                        </article>
                        <article class="tile is-child notification is-white">
                          <button class="button is-primary" @click="goToDevis">  Demander des devis</button>
                        </article>
                      </div>
                    </div>
                  </div>
                  <div class="tile is-parent is-vertical">
                    <article class="tile is-child notification is-white">
                      <div class="field ">
                        <label class="switch"> <p class="switchLegend py-2">Façades</p> 
                        <input type="checkbox" name="switchFaçade"  v-model="checkedFaçade" > 
                        <span class="slider round"></span> 
                        </label>
                      </div>
                      

                      <div class="field">
                        <label class="switch"> <p class="switchLegend py-2">Toitures</p>
                        <input type="checkbox" name="switchToiture" v-model="checkedToiture">
                        <span class="slider round"></span>
                        </label>
                      </div>
                      
                      <div class="field">
                        <label class="switch"><p class="switchLegend py-2">Combles</p>
                        <input type="checkbox" name="switchComble" v-model="checkedComble">
                        <span class="slider round"></span>
                        </label>
                      </div>
                      
                      <div class="field">
                        <label class="switch"><p class="switchLegend py-2">Chauffage</p>
                        <input type="checkbox" name="switchChauffage"  v-model="checkedChauffage">
                        <span class="slider round"></span>
                        </label>
                      </div>
                      
                      <div class="field">
                        <label class="switch"><p class="switchLegend py-2">Panneaux solaires</p>
                        <input type="checkbox" name="switchSolaire" v-model="checkedSolaire">
                        <span class="slider round"></span>
                        </label>
                      </div>
                      
                    </article>
                    <article class="tile is-child notification is-white">
                      <p class="has-text-weight-semibold">Coûts totaux estimés:</p>
                      <h1 class="is-size-2  has-text-weight-bold has-text-grey-light">{{ totalCost }} CHF</h1>


                    </article>
                  </div>
                </div>
              </article>
            </div>
            </div>

          </article>
        </div>
      </div>
    </div>
    <div class="tile is-parent">
      <article class="tile is-child notification is-white">
        <div class="card">
          <header class="card-header">
            <p class="card-header-title subtitle is-spaced has-text-weight-bold has-text-primary">
              Mon projet
            </p>
          </header>
          <div class="card-content">
            <p>Mes devis:</p>
            <h1 class="is-size-2  has-text-weight-bold has-text-grey-light">0</h1>
            <p class="is-size-5  has-text-weight-bold">Devis envoyés pour l'instant</p>
          
            <br>
            <p>Mes alertes:</p>
            <h1 class="is-size-2  has-text-weight-bold has-text-grey-light">0</h1>
            <p class="is-size-5  has-text-weight-bold">Alerte sur le prix du mazout</p>
          
          </div>
        </div>
      </article>
    </div>
  </div>

<link rel="stylesheet" href="https://cdn.materialdesignicons.com/4.9.95/css/materialdesignicons.min.css">

</div>
</template>


<script>
import axios from 'axios'
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'Simulations',
  components: {
  },
  data(){
    return {
      form: {
        adress_of_interest : '',
        rayon_of_interest : '',
        service_of_interest : '',
      },
      adresse:'',

      class_before:0,
      consumption_reduction: 0,
      saving_energy_cost: 0,
      reduction_co2:0,
      max_subv:0,
      coût_isolation_toiture:0,
      coût_isolation_facade:0,
      coût_remplacement_fenêtres:0,
      coût_remplacement_chauffage_pac:0,
      coût_solaire_thermique:0,
      duree_proftabilité: 0,

      /*checked:{
      checkedFaçade:true,
      checkedToiture:true,
      checkedComble:true,
      checkedChauffage:true,
      
      },
      */
      //checkedSolaire:true,

      errors:[],
    }
  },
mounted(){
    document.title='Simulations | Elona Construction'
    this.submitSimulation()
   
  },
  computed: {
        totalCost(){
      let ceiling = 0
      let walls = 0
      let windows = 0
      let heater = 0
      let solar = 0

      let checked = this.$store.getters.getChecked
      console.log("new checked",checked)

      if (this.checkedFaçade === true){
        walls = this.coût_isolation_facade
      }

      if (this.checkedToiture === true){
        ceiling = this.coût_isolation_toiture
      }

      if (this.checkedComble === true){
        windows = this.coût_remplacement_fenêtres
      }

      if (this.checkedChauffage === true){
        heater = this.coût_remplacement_chauffage_pac
      }

      if (this.checkedSolaire === true){
        solar = this.coût_solaire_thermique
      }
   

      let total = ceiling+walls+windows+heater+solar
      let totalFormat = total.toLocaleString( 'de-CH',{ 
        //style           : 'currency',
        //currency        : 'CHF',
        //currencyDisplay : 'symbol',
        useGrouping     : true
        } )

      return totalFormat
    },
    /*
    checkedSolaire: {
    get () {
      return this.$store.state.checkedSolaire
    },
    set (value) {
      this.$store.commit('setCheckedSolaire', value)
    }
  }, */

  /*checked:{
    get () {
      return this.$store.state.checked
    },
    set (value) {
      this.$store.commit('setChecked', value)
    }    

  },*/

    ...mapFields([

    'checked.checkedFaçade',
    'checked.checkedToiture',
    'checked.checkedComble',
    'checked.checkedChauffage',
    'checked.checkedSolaire'
      

    ]),



  },
  watch:{
    /*
    checked: {
      deep: true,
      immediate: true,
       handler(val) {
        this.$store.commit('setChecked', val)
        localStorage.setItem("checked", val)
      }, 
      
  }*/
    
  },

methods:{
  
    submitSimulation() {
    this.errors = []
    let adresse = this.$store.getters.getAdresse
    console.log("here is the adresse: ", adresse)
    this.adresse = adresse

    if (this.adresse === '') {
    this.errors.push('Adresse manquante')
    }
    if (!this.errors.length) {
      this.$store.commit('setIsLoading', true)
        
      this.sendData()
                    }
   },
    async sendData() {
    this.$store.commit('setIsLoading', true)
            
    let data = {
    'adresse': this.adresse,
    }

    console.log("data to send: ",data)

      await axios
                .post('/api/v1/housesim/', data)
                .then(response => {

                  console.log("RESPONSE SERVER: ",response.data)

                  this.class_before = response.data[0].class_before
                  this.consumption_reduction = response.data[0].consumption_reduction
                  this.saving_energy_cost = response.data[0].saving_energy_cost
                  this.reduction_co2 = response.data[0].reduction_co2
                  this.max_subv = response.data[0].max_subv
                  this.consumption_today = response.data[0].consumption_today
                  this.duree_proftabilité = response.data[0].duree_proftabilité
                  

                  this.coût_isolation_toiture = response.data[0].coût_isolation_toiture
                  this.coût_isolation_facade = response.data[0].coût_isolation_facade
                  this.coût_remplacement_fenêtres = response.data[0].coût_remplacement_fenêtres
                  this.coût_remplacement_chauffage_pac = response.data[0].coût_remplacement_chauffage_pac
                  this.coût_solaire_thermique = response.data[0].coût_solaire_thermique

                })
                .catch(error => {
                    this.errors.push('Something went wrong. Please try again')
                    console.log(error)
                })
      this.$store.commit('setIsLoading', false)
      },
    goToDevis(){
      this.$router.push('/devis')
    }
      

},
}

</script>

<style scoped>

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #1d492b;
}

input:focus + .slider {
  box-shadow: 0 0 1px #1d492b;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.switchLegend{
  padding-left:5rem;
}



</style>