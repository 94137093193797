import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'

import SignUp from '../views/SignUp.vue'
import LogIn from '../views/LogIn.vue'
import Simulations from '../views/Simulations.vue'
import TableauDeBord from '../views/TableauDeBord.vue'
import FormulaireDevis from '../views/FormulaireDevis.vue'
import ChoixSimulations from '../views/ChoixSimulations.vue'
import DemandeRecue from '../views/DemandeRecue.vue'





const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },

  {
    path: '/log-in',
    name: 'LogIn',
    component: LogIn
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: SignUp
  },
  {
    path: '/Simulations',
    name: 'Simulations',
    component: Simulations,

  },
  {
    path: '/ChoixSimulations',
    name: 'ChoixSimulations',
    component: ChoixSimulations,

  },
  {
    path: '/Demande-ok',
    name: 'DemandeRecue',
    component: DemandeRecue,

  },

  
  {
    path: '/devis',
    name: 'FormulaireDevis',
    component: FormulaireDevis,

  },
  

  {
    path: '/tableau-de-bord',
    name: 'TableauDeBord',
    component: TableauDeBord,
    meta: {
      requireLogin: true
    }
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated) {
    next({ name: 'LogIn', query: { to: to.path } });
  } else {
    next()
  }
})

export default router