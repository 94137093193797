import { createStore } from 'vuex'
import { saveStatePlugin } from '@/utils'
import defaultChecked from '@/default-checked'

import { getField, updateField } from 'vuex-map-fields';

const checked = JSON.parse(localStorage.getItem('checkedi')) || defaultChecked


export default createStore({
  plugins: [saveStatePlugin],
  state: {
    isAuthenticated: false,
    token: '',
    results: '',
    isLoading:false,
    adresse:'',
    checked,
    checkedSolaire: {}
  },
  getters: {
    getField,
    getCheckedField(state) {
      return getField(state.checked);
    },
    getAdresse(state){
      return state.adresse
    },
    getChecked(state){
      return state.checked
    },
    getCheckedSolaire(state){
      return state.checkedSolaire
    }
  },
  mutations: {
    updateField,
    setCheckedSolaire(state, checkedSolaire){
      state.checkedSolaire = checkedSolaire
    },

    setChecked(state, checked) {
      state.checked = checked
  },
    initializeChecked(state){
      if (localStorage.getItem('checkedSolaire')){
      state.checkedSolaire= localStorage.getItem('checkedSolaire')
      }else{state.checkedSolaire = {}}
  },

    setAdresse(state, adresse){
      state.adresse = adresse
      
    },
    initializeAdresse(state){
      if (localStorage.getItem('adresse')){
      state.adresse = localStorage.getItem('adresse')
      }else{state.adresse = ''}
    },

    initializeStore(state){
      if (localStorage.getItem('token')) {
        state.token = localStorage.getItem('token')
        state.isAuthenticated = true
    } else {
        state.token = ''
        state.isAuthenticated = false
    }
    },

    setToken(state, token) {
      state.token = token
      state.isAuthenticated = true
    },  
    removeToken(state) {
        state.token = ''
        state.isAuthenticated = false
    },
    setIsLoading(state,status){
      state.isLoading = status
    },
    },
  actions: {
  },
  modules: {
  }
})
